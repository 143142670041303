<template>
  <div class="col-md-4">
    <div class="card h-100 text-center">
      <div class="card-header">
        <h4>{{ name }} App</h4>
      </div>
      <img :alt="name + ' photo'" class="card-img-top" :src="image" />
      <div class="card-body">
        <p class="card-text">
          {{ summary }}
        </p>
      </div>
      <div class="card-footer">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="card">
                <button
                  type="button"
                  class="btn btn-primary"
                  data-toggle="modal"
                  :data-target="`#${modalId}`"
                >
                  Learn More
                </button>
                <learn-more-modal
                  :id="id"
                  :name="name"
                  :summary="summary"
                  :image="image"
                  :github="github"
                  :url="url"
                  :description="description"
                  :software="software"
                  :modalId="modalId"
                ></learn-more-modal>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <a
                  :href="url"
                  class="btn btn-info btn-md view-btn"
                  role="button"
                  aria-disabled="true"
                  target="_blank"
                  >View</a
                >
              </div>
            </div>
            <div class="col">
              <div class="card">
                <a
                  :href="github"
                  class="btn btn-dark btn-md git-btn"
                  role="button"
                  aria-disabled="true"
                  target="_blank"
                  >GitHub</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LearnMoreModal from "../modal/LearnMoreModal.vue";
export default {
  props: [
    "key",
    "id",
    "name",
    "summary",
    "image",
    "github",
    "url",
    "description",
    "software",
    "modalId",
  ],
  components: {
    LearnMoreModal,
  },
  data() {
    return {
      modalIds: "test-test",
    };
  },
};
</script>

<style scoped>
.card.h-100.text-center {
  box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.26), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.card-header,
.card-footer {
  background-color: #16697a;
}

.card-header {
  border-bottom: solid;
  border-bottom-color: black;
  border-width: 1px;
  border-radius: 10px 10px 0px 0px;
}

.card-footer {
  border-top: solid;
  border-top-color: black;
  border-width: 1px;
  border-radius: 0px 0px 10px 10px;
}

.card-header h4 {
  margin: auto 0px;
  color: #ede7e3;
}

.card-body {
  margin-top: auto;
  margin-bottom: auto;
}

.col-md-4 {
  margin: 20px 0px;
}

.card-img-top {
  border-radius: 0px;
  border-bottom: solid;
  border-bottom-color: black;
  border-width: 1px;
}

.learn-btn {
  background-color: #489fb5;
}

/* .view-btn {
  background-color: #FFA62B;
} */

.row {
  margin: 10px -10px;
  /* padding: 0px -9px; */
  /* background-color: lime; */
}
</style>
