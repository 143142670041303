<template>
  <section class="section">
    <div class="container-fliud">
      <div class="cover">
        <h1>Jason Hubbard</h1>
        <h3>Freelance Software Developer</h3>
        <p style="font-size: 18px">
          Hi, my name is Jason and I am a freelance software developer. Here are a
          few projects that I've worked on recently. Feel free to use the
          contact form to reach out.
        </p>
        <div class="container">
          <button
            type="button"
            class="btn btn-warning btn-lg"
            data-toggle="modal"
            data-target="#contactForm"
          >
            Contact Me
          </button>
          <contact-form-modal></contact-form-modal>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactFormModal from "../modal/ContactFormModal.vue";
export default {
  components: {
    ContactFormModal,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.section {
  /* height: 250px; */
  /* height: 250px; */
  background-image: url("/images/header_original.jpg");
  background-size: cover;
  background-color: #0f1113;
  /* background: chartreuse; */
  margin-bottom: 0px;
}

.cover {
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-left: 5%;
  padding-right: 5%;
  color: #ede7e3;
  /* background-color: black; */
}
</style>