<template>
  <section class="portfolio">
    <div class="container">
      <div class="row">
        <individual-project
          v-for="project in this.projects"
          :key="project.id"
          :id="project.id"
          :name="project.name"
          :summary="project.summary"
          :image="project.image"
          :github="project.github"
          :url="project.url"
          :description="project.description"
          :software="project.software"
          :modalId="project.modalId"
        ></individual-project>
      </div>
    </div>
  </section>
</template>

<script>
import IndividualProject from "./IndividualProject.vue";

export default {
  components: {
    IndividualProject,
  },
  props: ["projects"],
};
</script>

<style scoped>
.portfolio {
  background-color: #ede7e3;
}
</style>>

