<template>
  <about-me></about-me>
  <project-portfolio :projects="projects"></project-portfolio>
  <the-footer></the-footer>
</template>

<script>
import ProjectPortfolio from "./components/projects/ProjectPortfolio.vue";
import TheFooter from "./components/footer/TheFooter.vue";
import AboutMe from "./components/aboutme/AboutMe.vue";

export default {
  name: "App",
  components: {
    ProjectPortfolio,
    TheFooter,
    AboutMe,
  },
  data() {
    return {
      projects: [
        {
          id: "1",
          name: "Quote of the Day",
          summary:
            "Get a funny random movie/TV show quote sent to you every morning.",
          image: "/images/quote_of_the_day_thumbnail.jpg",
          github: "https://github.com/jmhubbard/quote_of_the_day_custom_user",
          url:
            "https://app-loader.jasonhubbard.dev/?url=quotes.jasonhubbard.dev",
          description:
            "The Quote of the Day App sends subscribed users a daily email that contains a random quote from one of their favorite shows. With a collection of over 2000 quotes from 24 different movies and television shows, subscribed users can edit their subscription preferences to choose which movies/shows they would like to receive.",
          software: ["Django", "Python", "Heroku ClI"],
          modalId: "quote-of-the-day",
        },
        {
          id: "2",
          name: "Cocktail Recipes",
          summary: "A collection of over 600 cocktail recipes.",
          image: "/images/cocktail_recipe_thumbnail.jpg",
          github:
            "https://github.com/jmhubbard/cocktails_website_using_cocktails_api",
          url:
            "https://app-loader.jasonhubbard.dev/?url=cocktail-recipes.jasonhubbard.dev",
          description:
            "The cocktails recipe website is a collection of over 600 different cocktail recipes. The website uses the Cocktail API to fetch all the relevant data for each cocktail. In case users don't know what they would like to make, the main page of the site shows three random drinks from the API. If users do know what they are looking for, there is an alphabetical dropdown list so users can view all drinks based on the first letter in the drink's name. Each cocktail contains a drink name, an ingredient list with measurements for each ingredient, mixing instructions, and an image of the cocktail.",
          software: ["Javascript", "Node.js", "Express", "Heorku CLI"],
          modalId: "cocktail-recipes",
        },
        {
          id: "3",
          name: "Cocktail API",
          summary: "A cocktail recipe API.",
          image: "/images/cocktail_recipe_api_thumbnail.jpg",
          github: "https://github.com/jmhubbard/cocktail_api",
          url:
            "https://app-loader.jasonhubbard.dev/?url=cocktail-api.jasonhubbard.dev/api",
          description:
            "The Cocktail API App is an API that uses the Django Rest Framework to provide cocktail recipes for over 600 different cocktails. Each cocktail contains a drink name, ingredient list with measurements for each ingredient, mixing instructions, and an image of the cocktail.",
          software: [
            "Django",
            "Python",
            "Django REST Framework",
            "Amazon S3 Bucket",
          ],
          modalId: "cocktail-api",
        },
        {
          id: "4",
          name: "Heroku Loading Page",
          summary:
            "A loading page for while a Heroku App wakes up. Click the view button to see the loading page or you can click the view button on any of the previous apps to see it in action.",
          image: "/images/heroku_loading_page_thumbnail.jpg",
          github: "https://github.com/jmhubbard/heroku-loading-page",
          url: "https://app-loader.jasonhubbard.dev/",
          description:
            "One of the restrictions while using the free Heroku tier, is that your app will go to sleep if it hasn't been visited in a while. This auto-sleeping can cause a long delay, upwards of 10 seconds, when a user is trying to wake the app for the first time. This delay leads to an unpleasant user experience because it looks as if the app is stuck loading. To alleviate this issue, the Heroku Loading Page App will ping the Heroku app URL to wake up the app and then redirect the user to the page when it receives an ok server status response. While the user is waiting to be redirected, there is a loading screen message and spinner so that the user understands that the app is loading.",
          software: ["Vue.js CLI"],
          modalId: "heroku-app-loading-page",
        },
        {
          id: "5",
          name: "Good Life Subscriber's",
          summary:
            "A members only page that allows current subscribers of The Good Life Meal Prep company to place their weekly meal orders.",
          image: "/images/the_good_life_thumbnail.jpg",
          github: "https://github.com/jmhubbard/Good_Life_Meal_Prep_Subscribers_Page",
          url: "https://subscribers.thegoodlifemealprep.com/",
          description:
            "The Good Life Meal Prep company owner offers his customers the option of subscribing to a meal prep plan instead of just buying meals every week. By subscribing to a plan, users receive a discount on the meals' cost by purchasing all the meals upfront. The companies website allowed the customers to purchase the meal plans but didn't offer them the ability to place their orders. The owner had to spend hours every week reaching out to his customers to get their orders, create invoices, and calculate how many meals were needed for the week. The Good Life Subscribers Page alleviates these issues by giving the customer a place to submit their weekly orders, keeps track of any special requests, automatically sends a confirmation email to the customer, and generates/emails the owner a CSV file that contains all the weekly orders.",
          software: [
            "Django",
            "Python",
            "Heroku CLI",
          ],
          modalId: "the-good-life-meal-prep-page",

        },
      ],
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
