<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-8 offset-md-2">
                    <div class="social-links">
                        <a class="social-btn" href="https://www.linkedin.com/in/jasonmichaelhubbard/">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                        <a class="social-btn" href="https://github.com/jmhubbard">
                            <i class="fab fa-github"></i>
                        </a>
                    </div>
                    <!-- <p class="text-center">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry <a href="#">Terms of Service</a> and <a href="#"> Privacy Policy</a> Copyright © 2019 - 2020. <a href="#">uiuxstream.com</a>
                    </p> -->
                </div>
            </div>
        </div>
    </footer>
</template>

<style scoped>
footer {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 5px 0px;
    margin-top: 0px;
    /* background-color: #fafafa; */
    background-color: #032330;

}
footer .social-links {
    text-align: center;
    margin: 5px 0px;
}
footer .social-btn {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: #258fb9;
    margin: 10px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 5px 10px 0px #909090;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    border: 1px dotted #fff;
}
footer .social-btn i {
    line-height: 50px;
    font-size: 22px;
    transition: 0.2s linear;
}
footer .social-btn:hover i {
    transform: scale(1.3);
    color: rgb(240, 197, 78);
}
footer .social-btn::before {
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    background: #ffffff;
    transform: rotate(45deg);
    left: -110%;
    top: 90%;
}
footer .social-btn:hover::before {
    animation: effect 0.6s 1;
    top: -10%;
    left: -10%;
}
footer p a {
    color: #ff5722;
}

/*-- Hover Animation Effect --*/
@keyframes effect {
    0% {
        left: -120%;
        top: 100%;
    }
    50% {
        left: 10%;
        top: -30%;
    }
    100% {
        top: -10%;
        left: -10%;
    }
}


/*-- Footer Responsive CSS --*/
@media (max-width: 576px){
footer .social-btn {
    width: 40px;
    height: 40px;
    margin: 5px;
}
footer .social-btn i {
    line-height: 40px;
    font-size: 18px;
}
}
</style>>