<template>
  <div
    class="modal fade"
    id="contactForm"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle" style="color: black;">Contact Form</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="content" style="text-align: left">
              <form
                class="form"
                name="contact"
                method="POST"
                netlify-honeypot="bot-field"
                data-netlify="true"
              >
                <input type="hidden" name="form-name" value="contact" />
                <p class="hidden">
                  <label>Don’t fill this out: <input name="bot-field" /></label>
                </p>
                <div class="form-group">
                  <label for="name" style="color: black"> Name: </label>
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    id="name"
                    placeholder="Enter Name"
                  />
                </div>
                <div class="form-group">
                  <label for="email" style="color: black">
                    Email Address:
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    id="email"
                    placeholder="Enter Email Address"
                  />
                </div>
                <div class="form-group">
                  <label for="message" style="color: black"> Message: </label>
                  <textarea
                    class="form-control"
                    name="message"
                    id="message"
                    placeholder="Enter Message"
                  ></textarea>
                </div>
                <div style="text-align: center">
                  <input
                    class="btn btn-primary"
                    type="submit"
                    value="Send message"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.hidden {
  display: none;
}
</style>